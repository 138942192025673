<template>
    <body>
    <div id="wrapper" class="bg-light">
        <AgentNav/>
        <div v-show="consumerRedirect" style="height: 300px">
            <div style="height: 0px; z-index: 200; position: fixed; top: 10%; left: 47.5%" >
                <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <nav v-if="!consumerRedirect" aria-label="breadcrumb" class=" d-md-block mb-0">
            <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                <li class="breadcrumb-item active" aria-current="Agent Dashboard">Dashboard</li>
            </ol>
        </nav>
        <main v-if="!consumerRedirect" id="main" role="main" class="px-3">
            <div v-if="isDraftrs() && activeUsers.length" class="text-center">
                <h4 @click="showActiveUserDetails=!showActiveUserDetails" class="hoverFlip">
                    <i class="fas fa-users"></i> <span class="badge badge-success badge-pill ml-2">{{ activeUsers.length }}</span>
                </h4>
                <div v-show="showActiveUserDetails" class="row">
                    <div class="col" v-for="au in activeUsers" v-bind:key="au.id">
                        <div class="mb-2 mt-2">
                            <strong class="mr-2 mb-1">
                                <span v-if="au.email">{{ au.email }}</span>
                                <span v-else>Unknown</span>
                            </strong>
                            <div>
                                <span v-if="au.role == 'ROLE_CONSUMER' || !au.role" class="badge badge-secondary">Consumer</span>
                                <span v-else class="badge badge-primary">Agent</span>

                                <a v-if="au.quoteId" class="ml-2" target="_blank" :href="'/quote/'+au.quoteId"><span class="badge badge-pill badge-warning">{{ au.quoteId }}</span></a>
                            </div>
                            <small class="text-muted">{{ fromNowTs(au.ts, true) }}</small>
                        </div>
                        <div class="card jsoncard">
                            <pre>{{ au }}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="!loaded" style="height: 0px; z-index: 200; position: fixed; top: 45%; left: 47.5%">
                <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="d-md-none pl-3 mt-1">
                <h5>Welcome back {{ user.firstName }} {{ user.lastName }}</h5>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <DashboardStats v-if="user.role !== 'ROLE_CONSUMER'"/>
                </div>
                <div v-if="!isObserver()" class="col-md-4">
                    <div class="card shadow-sm card-radius mt-2 mb-4">
                        <h3 class="card-header bg-transparent">New Quote</h3>
                        <div class="card-body text-center py-4" id="launch-quote-ctnr">
                            <!-- <router-link to="Quote" class="shadow btn btn-primary px-2 my-1">Start New Application</router-link> -->
                            <div class="dropdown">
                                <a href="Quote" target="_BLANK" class="shadow btn btn-primary px-2 my-1">Start New Application</a>
                            </div>

                            <div v-if="isDraftrs()">
                                <h4 class="py-2">or</h4>
                                <div id="app-upload" class="btn btn-primary px-2 my-2">
                                    <vue-dropzone disabled ref="dropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-md-12">
                    <div class="card shadow-sm card-radius mt-2">
                        <h3 v-if="!isObserver()" class="card-header bg-transparent">
                            <i class="far fa-address-book pr-1"></i> Accounts
                            <button :disabled="!loaded" class="shadow btn px-3 mx-2" @click="changeQuoteMode('mine')"
                                    v-bind:class="{ 'btn-primary': 'mine' == quoteMode, 'btn-outline-dark': 'mine' != quoteMode }">My Quotes
                            </button>
                            <button :disabled="!loaded" class="shadow btn px-3" @click="changeQuoteMode('all')"
                                    v-bind:class="{ 'btn-primary': 'all' == quoteMode, 'btn-outline-dark': 'all' != quoteMode }">All
                            </button>
                        </h3>
                        <div class="card-body">
                            <div class="tab-area">
                                <div class="bg-light p-3">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': null == quoteFilter}" @click="quoteFilter=null; increment=200">Recent
                                                Activity</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'new' == quoteFilter}" @click="quoteFilter='new'; increment=200">New
                                                Submissions</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'quoted' == quoteFilter}" @click="quoteFilter='quoted'; increment=200">Quoted</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'declined' == quoteFilter}"
                                               @click="quoteFilter='declined'; increment=200">Declined</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'issued' == quoteFilter}" @click="quoteFilter='issued'; increment=200">Issued</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'cancelled' == quoteFilter}"
                                               @click="quoteFilter='cancelled'; increment=200">Cancelled</a>
                                        </li>
                                        <li class="nav-item" role="presentation" v-if="'ROLE_SUPER' == user.role">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'archived' == quoteFilter}"
                                               @click="quoteFilter='archived'; increment=200">Archived</a>
                                        </li>
                                        <li class="nav-item" role="presentation" v-if="'ROLE_SUPER' == user.role">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'expiring' == quoteFilter}"
                                               @click="quoteFilter='expiring'; increment=300; searchQuotes()">Expiring</a>
                                        </li>
                                        <li class="nav-item" role="presentation" v-if="'ROLE_SUPER' == user.role">
                                            <a class="shadow-sm btn border m-1 nav-link" v-bind:class="{ 'active btn-primary': 'expired' == quoteFilter}"
                                               @click="quoteFilter='expired'; increment=300; searchQuotes()">Expired</a>
                                        </li>
                                    </ul>

                                    <div class="row input-group mb-2 pl-3">
                                        <input type="text" class="col-md-4 form-control" placeholder="Search" v-model="search" v-on:keyup.enter="searchQuotes()" aria-label="Search"
                                               aria-describedby="search">
                                        <button type="button" @click="clearSearch()" title="clear search" :class="search ? '' : 'd-none'" class="btn bg-transparent"
                                                style="padding-right: 8px; margin-left: -40px; z-index: 100;">
                                            <i class="fa fa-times"></i>
                                        </button>
                                        <!-- <select class="custom-select col-md-2 px-4 pl-2 text-center" v-model="searchBy" >
                                            <option value="all">Search Full Quote</option>
                                            <option value="policyNumber">Search by Policy #</option>
                                            <option value="id">Search by Quote #</option>
                                            <option value="customerId">Search by Customer #</option>
                                        </select> -->
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-dark" @click="searchQuotes()" type="button"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                    <div class="border border-warning mt-0 mb-2 p-1 pl-2 alert alert-warning" v-if="search !== '' && quotes.length == 0 && loaded">
                                        No results found for <b>&quot;{{ search }}&quot;</b>.
                                    </div>
                                    <div class="border border-success mt-0 mb-2 p-1 pl-2 alert alert-success" v-else-if="search !== '' && quotes.length > 0 && loaded && this.prevSearch == this.search">
                                        Found {{ quotes.length }} {{ quotes.length == 1 ? 'result' : 'results' }} for <b>&quot;{{ search }}&quot;</b>. <span v-if="quoteFilter">{{ quoteCount }} in {{ quoteFilter }}</span> <a v-if="canShowMore" href="#" @click.prevent="searchQuotes(1)" >Load more results.</a>
                                    </div>
                                    <div class=" mt-0 mb-2 p-1 pl-2" v-else>
                                        {{ quoteCount }} results. <a v-if="canShowMore" class="ml-3" href="#" @click.prevent="searchQuotes(1)">Load more results.</a>
                                    </div>
                                </div>

                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active overflow-auto" style="min-height: 300px" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <table style="min-width: 100%" class="table table-hover">
                                            <thead>
                                            <tr>
                                                <th><a href="#" class="text-secondary" @click.prevent="quoteSorter('insured')">Client <i class="fas"
                                                                                                                                         :class="quoteSort.col == 'insured' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th v-if="!isObserver()">Actions</th>
                                                <th><a href="#" class="text-secondary" @click.prevent="quoteSorter('status')">Status <i class="fas"
                                                                                                                                        :class="quoteSort.col == 'status' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th v-show="'all' == quoteMode && !isCarrierAdmin()"><a href="#" class="text-secondary" @click.prevent="quoteSorter('user')">Agent <i class="fas"
                                                                                                                                                                                      :class="quoteSort.col == 'user' ? (quoteSort.dir =='asc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th><a href="#" class="text-secondary" @click.prevent="quoteSorter('workType')">Product <i class="fas"
                                                                                                                                           :class="quoteSort.col == 'workType' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th><a href="#" class="text-secondary" v-b-tooltip.hover.bottomright="'Effective Date'" @click.prevent="quoteSorter('effective')">Eff <i class="fas"
                                                                                                                                                                                         :class="quoteSort.col == 'effective' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th><a href="#" class="text-secondary" v-b-tooltip.hover.bottomright="'Expiration Date'" @click.prevent="quoteSorter('expires')">Exp <i class="fas"
                                                                                                                                                                                        :class="quoteSort.col == 'expires' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th><a href="#" class="text-secondary" v-b-tooltip.hover.bottomright="'Last Updated'" @click.prevent="quoteSorter('updated')">Upd <i class="fas"
                                                                                                                                                                                     :class="quoteSort.col == 'updated' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a>
                                                </th>
                                                <th v-if="!isObserver()"><a href="#" class="text-secondary" v-b-tooltip.hover.bottomright="'Premium'" @click.prevent="quoteSorter('premium')">Prem <i
                                                        class="fas" :class="quoteSort.col == 'premium' ? (quoteSort.dir =='desc' ? 'fa-caret-down' : 'fa-caret-up') : ''"></i></a></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(quote, idx) in quotes" :key="idx" v-show="passesFilter(quote)">
                                                <td role='button' class="text-90" @click="goURL(quote.customerId, quote.id)">

                                                    <a href.prevent="#">{{ quote.insured ? quote.insured : '--' }} </a> <i
                                                        v-b-tooltip.hover="quote.policyNumber ? 'Quote: '+ quote.id + ' Policy: '+ quote.policyNumber + ' Customer: ' + quote.customerId + ' ' + quote.email  + ' Persons Name: ' + quote.owner : ' Quote: '+quote.id + ' ' + quote.email + '  Persons Name: ' + quote.owner"
                                                        class="far fa-question-circle fa-sm"></i>
                                                </td>
                                                <td v-if="!isObserver()" class="shortcuts pl-2">
                                                    <div class="dropdown">
                                                        <a class=" dropdown-toggle cursor-pointer text-90" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="mr-2 far fa-paper-plane"></i><i class="mr-2 fas fa-link"></i><i class="pr-2 fas fa-user-plus"></i><i
                                                                class="pr-1 fas fa-history"></i>
                                                        </a>
                                                        <div class="dropdown-menu shadow-lg p-2 py-4 mb-5 bg-white rounded border border-dark text-90" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item pb-2 " v-if="'issued' != quote.status && !quote.archived && 'ROLE_SUPER' == user.role" href="#"
                                                               @click.prevent="setArchive(quote, true)" v-b-tooltip.hover.right
                                                               title="Hide / Archive this quote from your dashboard. It will still exist in the archived section."><i class="pr-2 fas fa-archive"></i>
                                                                Archive Quote</a>
                                                            <a class="dropdown-item pb-2 " href="#" @click.prevent="getShareLink(quote)" v-b-tooltip.hover.right
                                                               title="Copy to your clipboard the link to access the quote / policy."><i class="pr-2 fas fa-link"></i> Copy Share Link</a>
                                                            <a class="dropdown-item pb-2 " v-if="'issued' == quote.status || 'cancelled' == quote.status" href="#"
                                                               @click.prevent="downloadPolicyDoc(quote)"><i class="pr-2 fas fa-file-download"></i> Download Policy</a>
                                                            <a class="dropdown-item pb-2 " href="#" @click.prevent="inviteInsured(quote)" v-b-tooltip.hover.right
                                                               title="An email will be sent to the insured to access their quote / policy."><i class="pr-2 far fa-paper-plane"></i>
                                                                {{ agencyBill ? 'Email Access to Retail Agent' : 'Email Access or Reminders to Insured' }}</a>
                                                            <a class="dropdown-item pb-2 " href="#" @click.prevent="getMailLog(idx)" v-b-tooltip.hover.right
                                                               title="Send an email message &amp; View the history of emails sent and opened by the insured."><i class="pr-2 fas fa-envelope"></i>Messaging
                                                                &amp; Email History</a>
                                                            <a v-if="!['declined', 'issued'].includes(quote.status)" class="dropdown-item pb-2 " href="#" @click.prevent="showQuoteletter(idx)"
                                                               v-b-tooltip.hover.right title="Preview and download a quoteletter pdf"><i class="pr-2 fas fa-file-download"></i> Preview and Download
                                                                Quoteletter</a>
                                                            <a v-if="!['declined', 'issued', 'cancelled'].includes(quote.status)" class="dropdown-item pb-2 " href="#"
                                                               @click.prevent="previewPolicy(quote)" v-b-tooltip.hover.right title="Preview and Download Draft Policy"><i
                                                                    class="pr-2 fas fa-file-download"></i> Preview and Download Draft Policy</a>
                                                            <a class="dropdown-item pb-2 " href="#" @click.prevent="reassignQuote(quote, idx)" v-b-tooltip.hover.right
                                                               title="Reassign this quote to another agent."><i class="pr-1 fas fa-user-plus"></i> Reassign Quote</a>
                                                            <a class="dropdown-item pb-2 " v-if="quote.archived && 'ROLE_SUPER' == user.role" href="#" @click.prevent="setArchive(quote, false)"><i
                                                                    class="pr-2 fas fa-undo"></i> Restore Quote</a>
                                                            <a class="dropdown-item pb-2 " href="#" v-if="quote.premium && 'new' == quote.status" @click.prevent="sendPaymentLink(quote, 'payment')"
                                                               v-b-tooltip.hover.right title="An email wil be sent to the insured with a link to the payment page."><i
                                                                    class="pr-2 fas fa-file-invoice-dollar"></i>
                                                                {{ agencyBill ? 'Email Consents and Bind Request to Insured' : 'Send Email with Payment Link' }}</a>
                                                            <a class="dropdown-item pb-2 " href="#" v-if="'issued' == quote.status && isExpiring(quote)"
                                                               @click.prevent="sendPaymentLink(quote, 'renewalnotice')" v-b-tooltip.hover.right
                                                               title="An email wil be sent to the insured with a link to the renewal payment page."><i class="pr-2 fas fa-redo"></i> Send Email with
                                                                Renewal Link</a>
                                                            <a class="dropdown-item pb-2 " href="#" v-if="['ROLE_ADMIN', 'ROLE_SUPER'].includes(user.role) && hasObject(quote, 'ratingId')"
                                                               @click.prevent="getQuoteBreakdownUrl(quote.ratingId)"><i class="pr-2">🔍</i> View Rate Matrix</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td role='button' @click="goURL(quote.customerId, quote.id)" class="text-90">
                                                    <span class="px-2 py-1 badge badge-primary w-100" v-if="'new' == quote.status && quote.premium">Quoted</span>
                                                    <span class="px-2 py-1 badge badge-dark w-100" v-else-if="'new' == quote.status">⭐️ New</span>
                                                    <span v-else-if="'issued' == quote.status" v-html="expireStatus(quote)"></span>
                                                    <span v-else-if="'agencyBillPending' == quote.status" v-html="expireStatus(quote)"></span>
                                                    <span class="px-2 py-1 badge badge-danger w-100" v-else-if="'cancelled' == quote.status">Cancelled</span>
                                                    <span class="px-2 py-1 badge badge-danger w-100" v-else-if="'declined' == quote.status">Declined</span>
                                                    <span class="px-2 py-1 badge badge-success w-100" v-else-if="'referral' == quote.status">🛎 Referral</span>
                                                    <span class="px-2 py-1 badge badge-light w-100 border border-dark" v-else>{{ quote.status[0].toUpperCase() + quote.status.substring(1) }}</span>

                                                </td>

                                                <!-- <td v-if="'all' == quoteMode && getClientDomain() == 'grange'" role='button' @click="goURL(quote.customerId, quote.id)" class="text-90" >
                                                    {{ quote.user }}
                                                    <div class="text-75">
                                                        <b>{{ quote.owners && quote.owners[0] != 'draftrs' && broker == 'draftrs' ? quote.owners[0] : '' }} </b> {{ quote.grange ? 'Agency: ' + quote.grange.agencyName : '' }} {{ quote.grange ? ', Code: ' + quote.grange.agencyCode : '' }}
                                                    </div>
                                                </td> -->
                                                <td v-if="'all' == quoteMode && !isCarrierAdmin()" role='button' @click="goURL(quote.customerId, quote.id)" class="text-90">
                                                    {{ quote.user }}
                                                    <br/><b>{{ quote.owners && quote.owners[0] != 'draftrs' && ['grange', 'accessone80', 'draftrs'].includes(broker) ? quote.owners[0] : '' }}</b>
                                                </td>

                                                <td role='button' @click="goURL(quote.customerId, quote.id)" class="text-90"><span class="main-color" v-if="quote.lob == 'mpl'"><b>MPL</b></span>
                                                    {{ quote.workType ? quote.workType : '--' }}, {{ quote.state }}
                                                </td>
                                                <td role='button' @click="goURL(quote.customerId, quote.id)" class="text-center text-90">{{ formatDate(quote.effective) }}</td>
                                                <td role='button' @click="goURL(quote.customerId, quote.id)" class="text-center text-90">{{ formatDate(quote.expires) }}</td>
                                                <td role='button' style="width: 120px" @click="goURL(quote.customerId, quote.id)">
                                                    <div class="text-90">{{ formatDate(quote.updated) }} <i class="pt-1 fas fa-info-circle text-90"
                                                                                                            v-b-tooltip.hover.topright="formatDate(quote.updated, 'h:mm a z', 1) + ' PST'"></i></div>
                                                </td>
                                                <td v-if="!isObserver()" role='button' @click="goURL(quote.customerId, quote.id)" class="text-90">
                                                    <template v-if="quote.premium">{{ quote.premium | currency }}</template>
                                                </td>
                                            </tr>
                                            <tr v-if="0 < quotes.length && quoteCount == 0">
                                                <td colspan="9" v-if="loaded">
                                                    <div class="m-0 alert alert-primary w-100" role="alert">
                                                        No Quotes found under {{ quoteFilter ? quoteFilter : 'recent activity' }}. <a href="/quote">Click here to start a new quote.</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="0 == quotes.length && search === ''">
                                                <td colspan="9" v-if="loaded">
                                                    <div class="m-0 alert alert-primary w-100" role="alert">
                                                        You haven't created any quotes yet. <a href="/quote">Click here to start a new quote.</a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr v-if="search !== '' && quotes.length == 0 && loaded">
                                                <td colspan="9" v-if="loaded">
                                                    <div class="m-0 alert alert-primary w-100" role="alert">
                                                        <a href="/quote">Click here to start a new quote.</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div v-if="canShowMore" class="text-center mt-4">
                                            <button @click="searchQuotes(1)" class="shadow btn btn-primary">More Results</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button ref="shareModal" type="button" class="d-none btn btn-primary" data-toggle="modal" data-target="#shareModal"></button>
            <button ref="assignModal" type="button" class="d-none btn btn-primary" data-toggle="modal" data-target="#assignModal"></button>
        </main>
        <div id="shareModal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-success" role="alert">
                            An email has been sent to <strong>{{ shareEmail }}</strong> with instructions for accessing their policy
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="assignModal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"><i class="fas fa-user-plus"></i> Quote Assignment</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            Assign this quote to the user selected below. The user will be notified via email.
                        </p>
                        <select v-if="0 < users.length" v-model="selectedUser" class="form-control" @change="updateQuoteUser()">

                            <option v-for="(user, idx) in users" :key="idx">{{ user.email }}</option>

                        </select>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="xl" ok-only v-model="hasEmailLog" id="log-modal" centered title="Messaging &amp; Email History">
            <div class="px-4 pt-2 bg-light border border-light rounded">
                <h6><i class="fas fa-link"></i> Copy Quote Access Link</h6>
                <div class="input-group mb-3">
                    <button class="btn btn btn-sm btn-primary rounded mr-2" type="button" @click="getCopyLink()" id="button-addon2">
                        <span v-if="copied">Copied <i class="fas fa-check"></i></span>
                        <span v-else>Copy Link</span>
                    </button>
                    <input type="text" class="form-control" style="max-width: 400px" :value="copyLink" placeholder="loading..." aria-label="Recipient's username" aria-describedby="button-addon2">
                </div>
            </div>
            <div class="py-2 text-center">
                <h6>- OR -</h6>
            </div>
            <div v-if="isJsonSet(quotes[currentIdx], 'email')" class="bg-light mb-2 px-4 pt-4 border border-light rounded">
                <h5><i class="fas fa-envelope"></i> Send Email Message</h5>
                <p class="ml-1 mt-3 text-90"><i class="fas fa-lightbulb text-warning"></i> Send an email message with quote/payment access to your client.</p>
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text font-weight-bold text-right" style="display: block; width: 70px" id="inputGroup-sizing-sm">From</span>
                    </div>
                    <select @click="updateMailTo" class="form-control" v-model="from" style="max-width: 180px">
                        <option selected value="support">support@draftrs.com</option>
                        <option value="me">me</option>
                    </select>
                </div>
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text font-weight-bold text-right" style="display: block; width: 70px" id="inputGroup-sizing-sm">To</span>
                    </div>
                    <input type="text" readonly :value="quotes[currentIdx].email" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                </div>
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text font-weight-bold" style="width: 70px" id="inputGroup-sizing-sm">Subject</span>
                    </div>
                    <input v-on:keyup="updateMailTo" type="text" id="subject" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                </div>
                <textarea v-on:keyup="updateMailTo" rows="6" class="form-control" :placeholder="'Hi ' + quotes[currentIdx].owner + '...'" id="message"></textarea>

                <div v-if="from == 'support'" class="form-group row ml-1 pt-2" @keydown.enter.stop>
                    <select class="form-control  inline" style="width: 260px" id="msgType">
                        <option v-if="quotes[currentIdx].status != 'issued'" selected value="access">Add Quote Link To Email</option>
                        <option v-if="quotes[currentIdx].status == 'issued'" selected value="access">Add Access Link To Email</option>
                        <option v-if="quotes[currentIdx].premium && 'new' == quotes[currentIdx].status" value="payment">Add Payment Link To Email</option>
                    </select>
                    <button
                            type="button"
                            :disabled="!quoteLoaded"
                            class="shadow-sm rounded text-center ml-2 py-1 btn-sm btn btn-primary btn-sm border border-dark"
                            @click.prevent="sendEmail()"
                    >
                        <i v-if="sendingEmail" class="fas fa-spinner fa-pulse"></i> Send Email
                    </button>
                </div>
                <div v-if="from == 'me'" class="form-group row ml-1 pt-2" @keydown.enter.stop>
                    <a
                            :href="mailto"
                            class="shadow-sm rounded text-center ml-2 py-1 btn-sm btn btn-primary btn-sm border border-dark"
                    >
                        <i v-if="sendingEmail" class="fas fa-spinner fa-pulse"></i> Send with My Email Client
                    </a>
                </div>
            </div>

            <p class="ml-2 mt-4 mb-2 text-90">Below is the Email log history for this quote sent from <b class="text-muted">support@draftrs.com</b>.</p>

            <table class="w-100 table table-striped table-responsive overflow-auto">
                <thead class="w-100">
                <tr>
                    <th width="2%">#</th>
                    <th width="23%">Time <i class="far fa-clock"></i></th>
                    <th width="13%">Event</th>
                    <th width="25%">Recipient Email</th>
                    <th width="27%" class="pl-3">Topic
                        <div class="float-right"><i @click="getMailLog(null, true)" v-b-tooltip.hover.left title="Reload" class="cursor-pointer fas fa-sync" :class="!quoteLoaded ? 'fa-spin' : ''"></i>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody v-if="emailLog" class="text-85">
                <tr v-for="(log, idx) in emailLog" :key="idx">
                    <td class="text-center">{{ idx + 1 }}</td>
                    <td v-html="fromNow(log.Timestamp)"></td>
                    <td v-html="log.Event.replace('Send', `Sent <i class='text-warning fas fa-angle-double-right'></i>`).replace('Open', `Opened <i class='text-success far fa-envelope-open'></i>`).replace('Click', `Link Clicked <i class='text-danger far fa-hand-point-right'></i>`)"></td>
                    <td>{{ log.ToAddress.replace('[', '').replace(']', '') }}</td>
                    <td class="pl-3">{{ log.Subject.replace('Your Draftrs', '').replace('!', '') }}</td>
                </tr>
                </tbody>
                <tbody v-if="emailLog && !emailLog.length">
                <tr>
                    <td colspan="5">No email logs found</td>
                </tr>
                </tbody>
            </table>
        </b-modal>
        <Quoteletter v-if="quoteletter" :quoteData="quotes[currentIdx]"/>
    </div>
    <StandardFooter/>
    </body>
</template>

<script>
import Vue from "vue";
import AgentNav from "../components/AgentNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import DashboardStats from "../components/DashboardStats.vue";
import Quoteletter from "../components/Quoteletter.vue";

import axios from 'axios';
import moment from 'moment';
import {encode} from 'html-entities';
import vue2Dropzone from 'vue2-dropzone';
import {getAuthToken, getUserData} from "../Utils/auth.js";
import {getClientDomain, getBrokerConfigs, getStyles, getStyle} from "../Utils/themer.js";

export default {
    name: "Dashboard",
    components: {
        AgentNav,
        StandardFooter,
        vueDropzone: vue2Dropzone,
        DashboardStats,
        Quoteletter
    },
    data: function () {
        return {
            quoteCount: null,
            quote: {workType: "", state: ""},
            dropzoneOptions: {
                thumbnailWidth: 150,
                url: '/api/import/quote',
                dictDefaultMessage: 'Upload an Application',
                headers: {"authentication": 'Bearer ' + getAuthToken()},
                previewTemplate: this.getDzTemplate()
            },
            quotes: [],
            loaded: false,
            user: getUserData(),
            activeUsers: [],
            quoteMode: 'mine',
            quoteFilter: null,
            shareEmail: null,
            search: '',
            prevSearch: '',
            resultsCount: 0,
            increment: 200,
            canShowMore: false,
            showActiveUserDetails: false,
            searchBy: 'all',
            orderBy: 'asc',
            quoteSort: {col: 'updated', dir: 'desc'},
            users: [],
            selectedUser: null,
            assignQuote: null,
            assignQuoteIdx: null,
            filter: '',
            emailLog: {},
            hasEmailLog: false,
            quoteLoaded: true,
            currentIdx: '',
            sendingEmail: false,
            copyLink: '',
            copied: false,
            mailto: '',
            from: 'support',
            consumerRedirect: true,
            quoteletter: false,
            broker: '',
            brokerConfigs: null,
            workTypes: null,
            agencyBill: false
        };
    },
    mounted() {
        window.scrollTo(0, 0);

        // set the broker themes again. This is when an agent from a subdomain just goes to draftrs.com to login vs rpsins.draftrs.com or another subdomain.
        let styles = getStyles();
        for (let i in styles) {
            const pc = getStyle(styles[i]);
            if (null !== pc) {
                document.documentElement.style.setProperty('--main-' + styles[i], pc);
            }
        }

        this.brokerConfigs = this.getBrokerConfigs();
        this.workTypes = this.brokerConfigs.workTypes;

        // check if has agencyBill
        if (Object.prototype.hasOwnProperty.call(this.brokerConfigs, 'agencyBill') && this.brokerConfigs.agencyBill) {
            this.agencyBill = true;
        }

        this.broker = this.getClientDomain();

        if (this.isObserver() || this.isCarrierAdmin())
            this.quoteMode = 'all';

        if (this.user.role == 'ROLE_CONSUMER') {

            // get quote data. If issued, send to policy, else send to quote
            this.redirectConsumer();
        } else {
            this.consumerRedirect = false;
            this.searchQuotes();
            this.refreshActivity();
        }
    },
    created: function () {
        this.dropzoneOptions.success = this.dropZoneSuccess;
    },
    computed: {
        loading () {
            return Vue.prototype.loading.value || this.consumerRedirect
        }
    },
    methods: {
        getQuoteBreakdownUrl: async function (ratingId) {
            let url = {};
            await axios.get('/api/audit/quote/' + ratingId).then((res) => {
                url = res.data;
            });

            window.open(url.url, '_blank');
        },
        previewPolicy: function (quote) {
            let url = '/api/doc/1/1/preview/1';
            axios({
                url: url,
                method: 'POST',
                responseType: 'blob', // important
                data: quote
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'policy-preview-' + quote.insured + '.pdf');
                document.body.appendChild(link);
                link.click();
            });
        },
        getBrokerConfigs() {
            return getBrokerConfigs();
        },
        showQuoteletter: function (idx) {
            if (this.isObserver())
                return;

            this.currentIdx = idx;
            if (!this.quoteletter)
                this.quoteletter = true;
            else if (this.quoteletter) { //this is to makeup for the stupid non value setting when closing the pdf
                this.quoteletter = false;
                setTimeout(() => this.quoteletter = true, 500);
            }

        },
        redirectConsumer: async function () {
            if (!this.user.quoteId)
                this.$router.push({name: 'quote'}).catch(() => {
                });

            await axios.get('/api/quote/' + this.user.quoteId).then((res) => {

                if (res.data.status == 'issued')
                    this.$router.push({name: 'policy', params: {customerid: res.data.customerId}}).catch(() => {
                    });
                else
                    this.$router.push({name: 'quote', params: {id: this.user.quoteId}}).catch(() => {
                    });
            });
        },
        goURL: function (customerId, quoteId) {
            if (this.isObserver())
                return;

            //let routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
            let routeData = '';
            if (customerId)
                routeData = this.$router.resolve({path: 'policy' + '/' + customerId});
            else
                routeData = this.$router.resolve({path: 'quote' + '/' + quoteId});

            window.open(routeData.href, '_blank');
        },
        setArchive: async function (quote, archived) {
            if (this.isObserver())
                return;

            Vue.set(quote, 'archived', archived);
            //quote.archived = archived;
            await axios.post('/api/attr/quote/' + quote.id, {"archived": archived}).then(() => {
                const msg = (archived) ? 'Quote has been archived successfully' : 'Quote has been restored successfully';
                this.$toast.open({
                    message: msg,
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });
            });
        },
        reassignQuote: async function (quote, idx) {
            if (this.isObserver() || this.isCarrierAdmin())
                return;

            this.assignQuote = quote.id;
            this.assignQuoteIdx = idx;
            this.selectedUser = quote.user;
            this.users = [];
            let users = await this.getUserList();
            users.sort(function (a, b) {
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            });

            //don't allow draftrs quotes to be assigned to non draftrs users
            //unless draftrs user
            if ('draftrs' !== getUserData().owner && quote.owners.includes('draftrs')) {
                for (let i in users) {
                    if ('draftrs' == users[i].owner) {
                        this.users.push(users[i]);
                    }
                }
            } else {
                this.users = users;
            }
            this.$refs.assignModal.click();
        },
        updateQuoteUser: async function () {
            if (this.isObserver())
                return;

            const params = {quoteId: this.assignQuote, user: this.selectedUser};
            await axios.post('/api/assign', params).then(() => {
                this.$toast.open({
                    message: 'Quote has been assigned to ' + this.selectedUser,
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });

                //set quote on dashboard
                let quote = this.quotes[this.assignQuoteIdx];
                quote.user = this.selectedUser;
                let owner = '';
                this.users.forEach(u => {
                    if (u.email == this.selectedUser) owner = u.owner;
                });
                quote.owners = [owner];
                Vue.set(this.quotes, this.assignQuoteIdx, quote);
            });
        },
        getUserList: async function () {
            this.loaded = false;
            let ret = [];
            await axios.get('/api/users').then((res) => {
                ret = res.data;
                this.loaded = true;
            });
            return ret;
        },
        newQuote: function () {
            this.$router.push({name: 'quote', params: {email: this.quote.email, state: this.quote.state, workType: this.quote.workType}});
        },
        changeQuoteMode: function (mode) {
            if (!this.loaded)
                return

            this.quoteMode = mode;

            this.searchQuotes();
        },
        formatDate: function (dt, format = 'MM/DD/YY', local = 0) {
            return local ? moment.utc(dt).local().format(format) : moment.utc(dt).format(format);
        },
        updateMailTo: function () {
            const message = document.getElementById('message').value ? encode(document.getElementById('message').value.replace(/(?:\r\n|\r|\n)/g, ' %0D%0A ')) : 'Your access link is ' + this.copyLink;
            const subject = document.getElementById('subject').value;

            // make mailto string
            this.mailto = "mailto:" + this.quotes[this.currentIdx].email + "?subject=" + subject + "&body=" + message;

        },
        downloadPolicyDoc: function (quote) {
            if (this.isObserver())
                return;

            let url = '/api/doc/' + quote.customerId + '/' + quote.policyNumber + '/policy';
            axios({
                url: url,
                method: 'POST',
                responseType: 'blob' // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', quote.policyNumber + '.pdf');
                document.body.appendChild(link);
                link.click();
            });
        },
        getCopyLink: function () {
            if (this.isObserver())
                return;

            navigator.clipboard.writeText(this.copyLink); // paste to clipboard
            this.copied = true;
        },
        getShareLink: async function (quote, noToast = null) {
            if (this.isObserver())
                return;

            this.loaded = false;
            this.copied = false;
            this.copyLink = '';
            let url;
            await axios.post('/api/share', {quoteId: quote.id}).then((res) => {
                url = res.data.url;
                this.loaded = true;
            });

            if (noToast) {
                this.copyLink = url;
                let subject = document.getElementById('subject');
                subject.value = 'Your ' + this.quotes[this.currentIdx].owners[0].charAt(0).toUpperCase() + this.quotes[this.currentIdx].owners[0].slice(1) + ' ' + this.quotes[this.currentIdx].workType + ' Quote for ' + this.quotes[this.currentIdx].insured;

            } else {
                navigator.clipboard.writeText(url); // paste to clipboard
                this.$toast.open({
                    message: 'Share Quote link has been copied to your clipboard',
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });
            }
        },
        passesFilter: function (quote) {

            if (!JSON.stringify(quote).toUpperCase().includes(this.filter.toUpperCase()))
                return false;
            switch (this.quoteFilter) {
                case null:
                    this.quoteCount = this.quotes.filter(quote => !quote.archived).length;
                    return !quote.archived;
                case 'new':
                    this.quoteCount = this.quotes.filter(quote => 'new' == quote.status && !quote.premium && !quote.archived).length;
                    return 'new' == quote.status && !quote.premium && !quote.archived;
                case 'quoted':
                    this.quoteCount = this.quotes.filter(quote => 'new' == quote.status && quote.premium && !quote.archived).length;
                    return 'new' == quote.status && quote.premium && !quote.archived;
                case 'issued':
                    this.quoteCount = this.quotes.filter(quote => 'issued' == quote.status && !quote.archived).length;
                    return 'issued' == quote.status && !quote.archived;
                case 'cancelled':
                    this.quoteCount = this.quotes.filter(quote => 'cancelled' == quote.status && !quote.archived).length;
                    return 'cancelled' == quote.status && !quote.archived;
                case 'declined':
                    this.quoteCount = this.quotes.filter(quote => 'declined' == quote.status && !quote.archived).length;
                    return 'declined' == quote.status && !quote.archived;
                case 'archived':
                    this.quoteCount = this.quotes.filter(quote => quote.archived).length;
                    return true === quote.archived;
                case 'expiring':
                    this.quoteCount = this.quotes.filter(quote => quote.status == 'issued' && moment.utc().diff(moment.utc(new Date(quote.expires)), "days") < 1 && moment.utc().diff(moment.utc(new Date(quote.expires)), "days") > -30).length;
                    return quote.status == 'issued' && moment.utc().diff(moment.utc(new Date(quote.expires)), "days") < 1 && moment.utc().diff(moment.utc(new Date(quote.expires)), "days") > -30;
                case 'expired':
                    this.quoteCount = this.quotes.filter(quote => quote.status == 'issued' && moment.utc(new Date(quote.expires)).isBefore(moment.utc())).length;
                    return quote.status == 'issued' && moment.utc(new Date(quote.expires)).isBefore(moment.utc());
                default:
                    this.quoteCount = this.quotes.length;
                    return false;
            }
        },
        clearSearch: function () {
            this.search = '';
            this.searchQuotes();
        },
        refreshActivity: async function () {
            let act;
            await axios.get('/api/activity').then((res) => {
                act = res.data;
            });
            this.activeUsers = act.users;

            return act;
        },
        searchQuotes: async function (more) {
            this.loaded = false;
            let result = {};
            let params = 'mine' == this.quoteMode ? {"user": this.user.email} : {};
            params[this.searchBy] = (this.searchBy == 'all') ? this.search + '*' : this.search;
            params['page'] = more ? Math.floor(this.resultsCount / this.increment) : 0;
            params['increment'] = this.increment;
            //params['order'] = this.orderBy;

            await axios.post('/api/quotes', params).then((res) => {
                result = more ? this.quotes.concat(res.data) : res.data;
                this.canShowMore = res.data.length < this.increment ? false : true;
                this.resultsCount = result.length;

                this.prevSearch = this.search;
            });

            // filter for hanover, markel, or any other carrier
            if ('ROLE_ADMIN' == this.user.role && 'draftrs' == this.user.owner) {
                if (this.user.company == 'hanover')
                    result = result.filter(quote => quote.lob && quote.lob == 'mpl');
                else if (this.user.company == 'markel')
                    result = result.filter(quote => !quote.lob || quote.lob && quote.lob == 'pl');
            }

            this.quotes = result;
            this.loaded = true;
        },
        dropZoneSuccess: function (file, response) {
            const res = JSON.parse(response);
            if (res.id) {
                this.$router.replace({name: "quote", params: {id: res.id}});
            }
        },
        getDzTemplate: function () {
            return `<div class="dz-preview dz-file-preview">
                <div class="dz-details">
                    <img data-dz-thumbnail />
                </div>
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>`;
        },
        getClientDomain() {
            return getClientDomain();
        },
        getMailLog: async function (idx = null, refresh = false) {
            this.loaded = false;
            this.currentIdx = idx = idx !== null ? idx : this.currentIdx;
            this.quoteLoaded = false;
            this.from = idx ? 'support' : this.from; // reset message to use draftrs email


            await axios.post('/api/mail/logs', {quoteid: this.quotes[idx].id, asc: false})
                    .then((res) => {

                        if (!refresh)
                            this.getShareLink(this.quotes[idx], true);

                        // filter duplicate logs. There are alot of repeats
                        this.emailLog = res.data.filter((thing, index, self) => index === self.findIndex((t) => (t.Event === thing.Event && t.Timestamp === thing.Timestamp && t.Subject === t.Subject)))
                        this.hasEmailLog = true;
                        this.loaded = true;
                        this.quoteLoaded = true;
                    })
                    .catch((e) => {
                        console.log(e);
                    });
        },
        fromNow: function (timestamp) {
            let time = '';
            if (moment().isSame(timestamp, 'day'))  // same day
                time = "<i class='text-danger fas fa-fire'></i> ";

            return time += moment(timestamp).format('LLL');

        },
        fromNowTs: function (ts, params) {
            return moment(ts).fromNow(params);
        },
        isDraftrs: function () {
            return (this.user && this.user.email.includes('@draftrs.com'));
        },
        isJsonSet: function (parent, child) {
            if (!parent)
                return false;

            if (Object.prototype.hasOwnProperty.call(parent, child))
                return true;

            return false;
        },
        isExpiring: function (policy) {
            return moment.utc().diff(moment.utc(new Date(policy.expires)), "days") < 1 && moment.utc().diff(moment.utc(new Date(policy.expires)), "days") > -60
        },
        expireStatus: function (policy) {
            if (policy.status == 'agencyBillPending') {
                return '<span class="px-2 py-1 badge badge-success w-100 bg-orange" >🕑 Pending</span>';
            } else if (policy.status != 'issued') {
                return '';
            } else {
                if (this.isExpiring(policy))
                    return '<span class="px-2 py-1 badge badge-warning w-100" >🕑 Expiring</span>';
                else if (moment.utc(new Date(policy.expires)).isBefore(moment.utc()))
                    return '<span class="px-2 py-1 badge badge-danger w-100" >📍 Expired</span>';
                else
                    return '<span class="px-2 py-1 badge badge-success w-100" ><i class="mr-1 fas fa-check"></i> Issued</span>'
            }
        },
        quoteSorter: function (column) {
            if (this.quoteSort.col == column) {
                this.quoteSort.dir = this.quoteSort.dir == 'asc' ? 'desc' : 'asc';
            } else {
                if (column == 'premium') {
                    this.quotes.sort((a, b) => parseFloat(a[column]) - parseFloat(b[column]));
                } else if (column == 'workType') {
                    this.quotes.sort(function (a, b) { // need this cuz sometimes its not a string
                        var textA = typeof a[column] == 'string' ? a[column].toUpperCase() : '';
                        var textB = typeof b[column] == 'string' ? b[column].toUpperCase() : '';
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    this.quotes.reverse();
                } else {
                    this.quotes.sort((a, b) => a[column].localeCompare(b[column]))
                }

                this.quoteSort = {col: column, dir: 'desc'}
            }

            this.quotes.reverse();

        }
    }
};
</script>

<style lang="scss">
.shortcuts a, .shortcuts i {
    color: #000;
}

#launch-quote-ctnr {
    height: 210px;
}

.jsoncard {
    font-size: 12px;
}

.hoverFlip {
    cursor: pointer;
    display: block; /* removes bottom margin/whitespace */
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}

.hoverFlip:hover {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
</style>
