<template>
    <section>
        <template v-if="loggedIn() && getRole() !== 'ROLE_CONSUMER'">
            <AgentNav/>
            <div class="text-center" v-if="loading">
                <h2 class="pt-3">🚀 Hang on while we load up!</h2>
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="align-middle spinner-border text-secondary" style="width: 5rem; height: 5rem;" role="status">
                        <span class="sr-only"> Loading...</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="container-fluid p-2">
                <header v-if="getClientDomain() !== 'draftrs'" class="d-none d-lg-block">
                    <nav :class="$route.name === 'endorse' || $route.name === 'quote' ? '' : 'fixed-top'" class="container-fluid navbar-expand-lg justify-content-between bg-white">
                        <div class="collapse navbar-collapse" style="height: 20px" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li v-if="['aibd'].includes(getClientDomain())" class="nav-item pt-2 mr-4">
                                    <a href="/professional-liability-insurance-buil" target="_BLANK" class="nav-link">Building Designers FAQ</a>
                                </li>
                                <li v-if="['grantprofessionals'].includes(getClientDomain())" class="nav-item pt-2 mr-4">
                                    <a href="/pdf/draftrs-grant-writers-faq.pdf" target="_BLANK" class="nav-link">Grant Writers FAQ</a>
                                </li>
                                <li class="nav-item pt-2">
                                    <a v-if="!inFrame()" href="/consumertoagent" class="nav-link"><i class="fas fa-lg fa-sign-in-alt main-color mr-1"></i>Agent Login</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <ConsumerQuoteNav v-if="['draftrs', 'localhost'].includes(getClientDomain())"/>
            </div>
        </template>
        <QuoteFlow v-if="lob === 'pl'" :saveQuoteInProgress="saveQuoteInProgress" :issueAction="issueAction"/>
        <QuoteFlowMPL v-if="lob === 'mpl'" :saveQuoteInProgress="saveQuoteInProgress" :issueAction="issueAction"/>
        <line-of-business v-else-if="lob === 'cherub'" :issueAction="issueAction" :schema="cherub.schema"/>
    </section>
</template>

<script>
import axios from 'axios';
import {isLoggedIn, getUserData} from "@/Utils/auth";
import {getClientLogo, getClientDomain} from "@/Utils/themer";
import AgentNav from "@/components/AgentNav.vue";
import ConsumerQuoteNav from "@/components/ConsumerQuoteNav.vue";
import QuoteFlow from "@/components/QuoteFlow.vue";
import QuoteFlowMPL from "@/components/QuoteFlowMPL.vue";
import LineOfBusiness from "@/components/Quote/LineOfBusiness.vue"
import cherub from '@/schemas/cherub.js'

export default {
    name: "Quote",
    components: {
        LineOfBusiness,
        AgentNav,
        ConsumerQuoteNav,
        QuoteFlow,
        QuoteFlowMPL
    },
    props: {},
    data: function () {
        return {
            cherub: cherub,
            lob: null,
            industry: null,
            issueAction: 'issue',
            saveQuoteInProgress: true,
            loading: true
        };
    },
    mounted() {
        // for draftrs.com/quote url
        // /quote with no id param and nobody is logged in redirects to /getaquote which is same as /start
        // both of which go thru StartQuote.vue which creates a consumer session then redirects to /quote
        if (!this.loggedIn() && !this.$route.params.id) {
            return this.$router.push('getaquote');
        }

        if (this.isObserver())
            return;

        if (this.$route.name === 'renew') {
            this.issueAction = 'renew';
            this.saveQuoteInProgress = false;
        } else if (this.$route.name === 'endorse') {
            this.issueAction = 'endorse';
            this.saveQuoteInProgress = false;
        }

        if (this.$route.params.id)
            this.getQuoteData(this.$route.params.id);
        else if (this.$route.query.lob) {
            this.lob = this.$route.query.lob;
            this.loading = false;
        } else {
            this.lob = 'pl';
            this.loading = false;
        }

    },
    methods: {
        loggedIn: function () {
            return isLoggedIn();
        },
        getClientDomain() {
            return getClientDomain();
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getRole() {
            return getUserData().role;
        },
        inFrame() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        async getQuoteData(quoteId) {
            let res = await axios.get('/api/quote/' + quoteId).catch(error => {
                this.$toast.open({
                    message: 'Invalid quote id',
                    type: 'error',
                    dismissible: true,
                    duration: 100000,
                    position: 'top-right'
                })
                console.error(error)
                this.loading = false;
            })
            let quote = res.data;
            this.loading = false;

            // check for snapshots
            if (this.issueAction === 'renew' && quote.transactions && quote.transactions.renew) {
                this.$route.params.snapshotId = quote.transactions.renew;
            } else if (this.issueAction === 'endorse' && Object.prototype.hasOwnProperty.call(quote, 'transactions') && quote.transactions.endorse) {
                this.$route.params.snapshotId = quote.transactions.endorse;
            }
            if (Object.prototype.hasOwnProperty.call(quote, 'lob')) {
                this.lob = quote.lob;
            } else {
                this.lob = 'pl';
            }
        }
    }
};
</script>

<style lang="scss">
</style>
